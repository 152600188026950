<template>
  <div>
    <heads :more="showMore" :msg="title_msg"></heads>
    <div class="all">
      <van-list
        v-show="showDatas"
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="loadMore"
      >
        <div v-for="(item, index) in list" :key="index" class="main" @click="go_users(item)">
          <div class="main_left">
            <img class="xiaozu_img" onerror="javascript:this.src='https://newoss.zhulong.com/tfs/noavatar_big.gif'" :src="item.avatar" alt>
          </div>
          <div class="main_center">
            <p class="title">{{ item.username }}</p>
            <p class="marginTop">
              <img width="13" height="14" src="@/static/images/my_index/fensi.png">
              {{ item.num }}个粉丝&nbsp;&nbsp;
              <img
                width="15"
                src="https://newoss.zhulong.com/edu/202007/29/44/121244cbondyllgzczt6un.png"
              >
              {{ item.count }}人关注
            </p>
          </div>
          <div class="main_right">
            <img class="youjiantou_img" src="../../static/images/my_index/youjiantou_img.png" alt>
          </div>
        </div>
      </van-list>
      <div v-show="!showDatas" class="nodatas">
        <img src="@/static/images/my_index/kong.png" alt="kong">
        <p>您还没有关注的用户~</p>
      </div>
    </div>
  </div>
</template>

<script>
import heads from '@/compontens/heads.vue'
import { get_longyou_list } from '@/api/my_user.js'
import commontop from '@/compontens/menu.vue'

export default {
  components: {
    heads,
    commontop
  },
  data() {
    return {
      title_msg: '关注的龙友',
      list: [],
      isshow: false,
      showMore: true,
      limit: 15,
      page: 1,
      count: 0,
      showDatas: true,
      loading: false,
      finished: false
    }
  },
  mounted() {
    // console.log(11111);
    // this.get_list()
  },
  methods: {
    clicks() {
      if (this.isshow == false) {
        this.isshow = true
      } else {
        this.isshow = false
      }
    },
    loadMore() {
      const post = {
        page: this.page,
        limit: this.limit
      }
      get_longyou_list(post).then((res) => {
        this.loading = false
        const result = res.result ? res.result : {}
        this.count = result.count ? result.count : 0
        const datas = result.list ? result.list : []
        this.list = [...this.list, ...datas]
        // 是否有数据
        if (this.list.length > 0) {
          this.showDatas = true
          // 是否有更多
          if (this.list.length < this.count) {
            this.page++
            this.finished = false
          } else {
            this.finished = true
          }
        } else {
          this.showDatas = false
        }
      })
    },
    go_users(item) {
      window.location.href = `https://m.zhulong.com/bbs/ucenter?uid=${item.uid}`
    }
  },
  beforeRouteEnter(to, from, next) {
    window.document.body.style.backgroundColor = '#f7f7f7'
    next()
  },
  beforeRouteLeave(to, from, next) {
    window.document.body.style.backgroundColor = ''
    next()
  }
}
</script>

<style lang="scss" scoped>
.nodatas {
  margin-top: 250px;
  text-align: center;
  p {
    color: #333;
    font-size: 26px;
    margin-top: 20px;
  }
}
.img_h {
  width: 40px;
  height: 40px;
  position: absolute;
  right: 30px;
  top: 44px;
  z-index: 1;
}
.all {
  width: 100%;
}
.main {
  width: 687px;
  height: 172px;
  background: #fff;
  margin: 22px auto;
  border-radius: 12px;
  overflow: hidden;
}
.main_left {
  width: 153px;
  float: left;
}
.main_center {
  width: 375px;
  // background: navy;
  float: left;
}
.xiaozu_img {
  width: 105px;
  height: 105px;
  margin: 38px 0 0 20px;
  border-radius: 50%;
}
.msgs {
  color: #9f9f9f;
}
.marginTop {
  margin-top: 30px;
  text-align: left;
  height: 40px;
  line-height: 40px;
  color: #999;
  font-size: 24px;
}
.marginTop img {
  vertical-align: sub;
  margin-right: 4px;
  margin-bottom: 1px;
}
.main_right {
  width: 31px;
  float: right;
  margin-top: 76px;
}
.follow_btn {
  width: 91px;
  height: 44px;
  border: 3px solid #7c7c7c;
  background: #fff;
  border-radius: 10px;
  color: #9d9d9d;
}
.title {
  color: #333;
  font-size: 30px;
  padding-top: 32px;
}
.youjiantou_img {
  width: 15px;
  height: 29px;
}
</style>
